export const BusinessRouterPaths = {
    account: '/web/account',
    dashboard: '/web/dashboard',
    deviceManager: '/web/devices/manage-devices',
    deviceUpdate: '/web/devices/update-device',
    inventories: '/web/inventories',
    invoices: '/web/invoices',
    invoiceDetail: '/web/invoices/:id',
    productDetail: '/web/products/:id',
    productList: '/web/products',
    labelerTemplates: '/web/labeler/templates',
    stockOrderImport: '/web/stock-order/import',
    stockOrderList: '/web/stock-order/list',
    stockReceiptList: '/web/stock-receipts/list',
    stockReceiptImport:'/web/stock-receipts/import',
    dispatchNotificationsList: '/web/dispatch-notifications/list',
    dispatchNotificationsImport: '/web/dispatch-notifications/import',
    productsWithoutBarcodeReport: '/web/reports/products-without-barcode',
    productsWithDuplicateNameReport: '/web/reports/products-with-duplicate-name',
    productsNotMappedReport: '/web/reports/products-not-mapped',
    expectedStockByWeekReport: '/web/reports/expected-stock-by-week',
    unknownIdentifierHistoryReport: '/web/reports/unknown-identifiers',
    rmsItemIssuesReport: '/web/rms/items-mismatch',
    rmsCostPriceReport: '/web/rms/cost-price',
    settings: '/web/settings',
    userManager: '/web/users',
    userDetail: '/web/users/:id',
    newUser: '/web/users/new',
    asyncTasks: '/web/async-tasks',
    businessList: '/web/business/list-businesses',
    businessQueueTasks: '/web/business/business-queue-tasks',
    businessSyncTasks: '/web/business/business-sync-tasks',
    businessUpdate: '/web/business/update-business/:id',
    createbusiness: '/web/business/create-business',
    storePriceReport: '/web/reports/store-price-report',
    supplierEntryMultiplesInSameItemScreen : '/web/reports/supplier-entry-multiples-in-same-item',
    // productPriceDetailbySoldScreen: '/web/reports/product-price-detail-by-sold-screen',
    // productsReport: '/web/reports/products'
    priceMissingOrZeroReport :'/web/reports/price-missing-or-zero',
    salesDataSummary: '/web/reports/sales-data-summary',
    productsWithMarginLessThanZero:'/web/reports/products-with-margin-less-than-zero',
    productMargins:'/web/reports/product-margins',
    supplierRocInMultipleItems: '/web/reports/supp-roc-in-multiple-items',
    cgByEBTSectorDiscSummaryReport:'/web/reports/cd-by-ebt-sector-disc-summary',
    webStoreSettings : '/web/store/settings',
    importFile :'/web/store/file-import',
    tagsToRemoveCode :'/web/reports/tags-to-remove-code',
    tagsToRemovePrice : '/web/reports/tags-to-remove-price',
    ccrsHomeScreen: '/web/ccrs/home',
    ccrsReportListScreen : '/web/ccrs/report-list',
    stockReceiptsImport:  '/web/stock-receipts/import',
    suggestPriceByMargin : '/web/stock-receipts/sugg-price-by-margin',
    suggestPriceByMarginDetail: '/web/stock-receipts/sugg-price-by-margin/detail/:id',
    marginByCgList: '/web/margin-by-cg/list',
    priceRoundList : '/web/price-round/list',


    ccrsReportDetailListurl : '/web/ccrs/report-content/:id',
    liquorLiterTaxMismatch: '/web/reports/liquour-liter-tax-mismatch',
    productsMostRecentSR: '/web/reports/prods-most-recent-sr-by-supplier',


    signagePlaylist: '/web/signage/playlist',
    signagePlaylistDetails: '/web/signage/playlist/:id',

    customerOrderSummary: '/web/customer-orders/summary',
    customerOrderPickGroups: '/web/customer-orders/pick-groups',
    customerOrderList: '/web/customer-orders/',

};

export const SupplierRouterPaths = {
    account: '/web/account',
    dashboard: '/web/dashboard',
    businesses: '/web/supplier-businesses',
    invoices: '/web/invoices',
    invoiceDetail: '/web/invoices/:id',
    productDetail: '/web/products/:id',
    productList: '/web/products',
    settings: '/web/settings',
};

export const MasterDataRouterPaths = {
    masterGlobalLookup: '/web/master-data/product-lookup',
    masterSuppliers: '/web/master-data/suppliers',
    masterProductDetail: '/web/master-data/products/:id',
    masterProducts: '/web/master-data/products',
    masterBrands: '/web/master-data/brands',
    masterBrandDetail: '/web/master-data/brands/:id',
    masterCompany:'/web/master-data/master-company',
    masterCompanyDetail:'/web/master-data/master-company/:id',
    masterProductsLink: '/web/master-data/lCgByEBTSectorDiscSummaryScreenink-products',
    masterSuppliersLink: '/web/master-data/link-suppliers',
    masterBusinessSuppliersLink: '/web/master-data/link-business-suppliers',
    // masterSupplierProductsLink: '/web/master-data/link-supplier-products',
    // masterBusinessSupplierProducts: '/web/master-data/supplier-products',
    masterProductsWithoutBarcodeReport: '/web/master-reports/products-without-barcode',
    masterProductsWithoutQtyValueReport: '/web/master-reports/products-without-qty-value',
    masterProductsWithoutUnitMeasureReport: '/web/master-reports/products-without-unit-measure',
    masterProductDuplicates: '/web/master-reports/product-duplicates',
    masterProductNameUnitMismatchReport: '/web/master-reports/product-name-unit-mismatch',
    // masterProductsWithoutBarcodeReport: '/web/reports/master-data/products-without-barcode',
    masterSupplierProductsWithoutBarcodeReport:
        '/web/reports/master-data/supplier-products-without-barcode',
    masterSupplierProductsWithoutCaseCodeReport:
        '/web/reports/master-data/supplier-products-without-case-code',
    masterSupplierProductsWithoutCaseQtyReport:
        '/web/reports/master-data/supplier-products-without-case-quantity',
    masterSupplierProductsWithoutUnitMeasureReport:
        '/web/reports/master-data/supplier-products-without-unit-measure',
    produceCodesReport: '/web/master-reports/produce-codes',
    supplierProductListReport: '/web/master-reports/supplier-product-list',
    retailerCodeNotInLinkedMasterProductIdentifierReport : '/web/master-reports/retailer-code-not-in-linked-master-product-identifier',
    masterIdentifierDuplicates: '/web/master-reports/products-duplicates-identifier',
    masterBulkProductsLink:'/web/master-data/master-bulk-products-link',
    getRetailerBusinessList: '/web/master-data/retailer-business-list',
    masterSupplierDataImport: '/web/master-data/master-supplier-data-import',
    supplierReorderCodeMismatchReport : '/web/master-reports/supplier-reorder-code-mismatch',
    supplierProductNotLinkedToMasterReport : '/web/master-reports/supplier-items-not-linked-to-master',
    retailersSupplierItemsNotLinkedToMasterItemReport : '/web/master-reports/retailers-supplier-items-not-linked-to-master-item',
    masterProductBrandMismatchReport: '/web/master-reports/product-brand-mismatch',
    masterBusinessProductNameMismatchReport : '/web/master-reports/master-business-product-name-mismatch',
    masterProductOrphansReport:'/web/master-reports/master-product-orphans',
    masterItemLinkedRetailerMultipleItemsReport : '/web/master-reports/master-item-linked-retailer-multiple-items',
    masterIdentifierCQMismatchSuppProductCQReport: '/web/master-reports/mpi-cq-mismatch-supplier-cq',
    suppliersLinkedToRetailersReport: '/web/master-reports/suppliers-link-retailer',
    retailerCodesWithinGroupNotInMasterIdentifierReport: '/web/master-reports/retailer-codes-within-group-not-in-master-identifier',
    companyProductsListScreen :'/web/master-data/company-products-list',
    companyProducts: '/web/master-data/company-products',
    companyProductDetail:'/web/master-data/company-products/:id',
    masterItemLinkedToDeletedItemReport: '/web/master-reports/master-item-linked-to-deleted-item',
    masterCompanyDataImport: '/web/master-data/import-company-data',
    linkBusinessMasterCompany: '/web/master-data/link-business-master-company-suppliers',
    rocBySupplierCompany:'/web/master-reports/roc-by-supp-company',
    rtlAddedTempProductsList: '/web/master-data/approve-temp-prods',
    rtlAddedTempProductDetail: '/web/master-data/approve-temp-prod/detail/:id',
    rtlAddedProdDetail :'/web/master-data/approve-temp-prod/detail',
    hnSuppUpdateSummary: '/web/master-reports/hn-supp-update-summary',
    apnaBazaarHomeneedsDataMismatch: '/web/master-reports/ab-hn-data-mismatch',
    apnaBazaarHomeneedsCostToPriceMismatch: '/web/master-reports/ab-hn-cost-to-price-mismatch',
    containerSubItemsLinks: '/web/master-reports/container-subitems-links',


};
