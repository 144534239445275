import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import {
  Typography, Paper, Stepper, Step, StepLabel, TextField, Button, Dialog, DialogActions,
  DialogTitle, DialogContent, DialogContentText, CircularProgress, Tooltip, IconButton, Divider
} from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {
  StockOrderItemDatum, StockOrderRequest,
  initialStockOrderSummary, initSpecifiHeaders,
  GridElement, stockOrderHeaders, MatchInvoicePayload, EXPECTED_HEADERS
} from '../../models';
import { getMatchedInvoiceItems, createStockOrderAPI, clearStockOrderProducts, createStockOrderSuccess } from '../../data/actions';
import { BusinessSupplierLinkRecord } from '../../../master-data/models';

import { useTheme } from '@material-ui/core/styles';
import Papa from 'papaparse';
import sortBy from 'lodash/sortBy';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { ERROR_500, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { FileImport, ConfirmSupplierAndLocation, VerifyImportData, MatchInvoiceProduct } from '../../components';
import { validateHeaders, getInvoiceItemsToMatch, getMatchedHeaders, getMatchedItemSummary, getStockOrderRequestData } from './helper';
import { PageToolbar } from '../../../page/components';
import { PaginatedResponse } from '../../../shared/models';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import useWindowDimensions from '../../../shared/hooks/WindowDimensions';
import { getLinkedKoronaMasterCompanySuppliers } from '../../../master-data/api';
import { uploadInvoicePDf, parsePdf, getSOLinkedSuppliers, addStockOrder } from '../../api';
import moment from 'moment';
import { store } from 'web/src/index';
import { Location, LocationBusiness } from '../../../shared/models';
import {
  setSelectedLocations,
  setSelectedBusiness,
} from '../../../../store/actions/userLocation';
import cloneDeep from 'lodash/cloneDeep';
import GlobalStyles from '../../../../styles/GlobalStyles.web';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    secondaryPageTitle: {
      fontWeight: 400,
      fontSize: '1.15rem',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      backgroundColor: '#fff',
      paddingLeft: 24,
      paddingRight: 24
    },

    backButton: {
      marginRight: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

const getSteps = () => {
  return ['Import Invoice Data', 'Confirm Supplier & Location', 'Verify Imported Data', 'Map Stock Order Items', 'Finish'];
};

const NoError = {
  hasError: false,
  message: ''
};

const NoErrorInHeaders = {
  hasError: false,
  message: '',
  message2: ''
};

const initialSupplierOptions = {
  stockOrder: false,
  preselectedSupplier: true
}



const StockOrderImportScreen = (props: any) => {
  const classes = useStyles({});
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [uiLoading, setUILoading] = useState(false);
  const [linkedSuppliers, setLinkedSuppliers] = useState([]);
  const [parsedPdfData, setParsedPdfData] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState(null as any);
  const [textractModel, setTextractModel] = useState("azure_textract" as any);
  const [selectedFileError, setSelectedFileError] = useState(NoError);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierError, setSupplierError] = useState(NoError);
  const [definedHeaders, setDefinedHeaders] = useState(stockOrderHeaders);
  const [gridData, setGridData] = useState<GridElement[][]>([]);
  const [mappedHeaders, setMappedHeaders] = useState(new Map<number, string>());
  const [headersError, setHeadersError] = useState(NoErrorInHeaders);
  const [checkRowsInput, setCheckRowsInput] = useState('');
  const [checkColumnsInput, setCheckColumnsInput] = useState('');
  const [stockOrderSummary, setStockOrderSummary] = useState(initialStockOrderSummary);
  const [specificHeaders, setSpecificHeadersMap] = useState(initSpecifiHeaders);
  const [caseQtyDataError, setCaseQtyDataError] = useState(NoError);
  const [isCaseQtyMapped, setIsCaseQtyMapped] = useState(false);
  const [stepOneSuccess, setStepOneSuccess] = useState(false);
  const [description, setDescription] = useState(`Invdt:-           Invnum:-`);
  const [isPDFUploaded, setPDFUploaded] = useState(false);
  const [stockOrderProducts, setStockOrderProducts] = useState([]);
  const [shouldUpdateRowColSelection, triggerRowColSelection] = useState(false);
  const selectedOrgUnit = useRef(null as any);
  const [createStockOrderButtonDisabled, setCreateStockOrderButtonDisabled] = useState(false);
  const [backButtonDisabled, setBackButtonDisabled] = useState(false);
  const [awsBlockResponseId, setAwsBlockResponseId] = useState<number>(0);
  const [isFileExtensionPDF, setIsFileExtensionPDF] = useState(true);
  const [supplierLinked, setSupplierLinked] = useState(true);
  const [supplierNameNotLinked, setSupplierNameNotLinked] = useState('');
  const { width, height } = useWindowDimensions();
  const [stepTwoSuccess, setStepTwoSuccess] = useState(false);
  const [stepThreeSuccess, setStepThreeSuccess] = useState(false);
  const [stepFourSuccess, setStepFourSuccess] = useState(false);
  const [expressMode, setExpressMode] = useState(false);
  const [pdfInfoExists, setPDFInfoExists] = useState(false);
  const [reloadMappingButtonDisabled, setReloadMappingButtonDisabled] = useState(false);
  const [masterCompanyExists, setMasterCompanyExists] = useState(false)

  const [jsonUrl, setJsonUrl] = useState(null as any)
  const [vendorJson, setVendorJson] = useState(null as any)
  const [vendorProdData, setVendorProdData] = useState(null as any)
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [nextbtnDisabled, setNextBtnDisabled] = useState(true)
  const [stockOrderNumber, setStockOrderNumber] = useState(null)
  const [selectedSupplierNone, setSelectedSupplierNone] = useState(false)
  const [showAlertRtlLocNotFound, setShowAlertRtlLocNotFound] = useState(false)
  const [rtlLocErrorMsg, setRtlLocErrorMsg] = useState('')
  const [supplierOption, setSupplierOption] = useState(cloneDeep(initialSupplierOptions));
  const [selectedSupplierManually, setSelectedSupplierManually] = useState(null)
  const [suppNotFound, setSuppNotFound] = useState(false)
  const [selectedParserSetting, setSelectedParserSetting] = useState(null)
  const [shouldSelectParserSetting, setShouldSelectedParserSetting] = useState(false)
  const theme = useTheme();
  const [deleteLinkDialogOpen, setDeleteLinkDialogOpen] = useState(false)
  const [selectedItemtoUnlink, setSelectedItemtoUnlink] = useState(null)
  const [parserModel, setParserModel] = useState(null)
  const [addtlExpData, setAddtlExpData] = useState([{ type: '', amount: '' }])
  const [soComment, setSOComment] = useState('')

  const [originalDataSummary, setOriginalDataSummary] = useState(null)

  useEffect(()=>{
    console.log("stockOrderSummary", stockOrderSummary)
  },[stockOrderSummary])

  useEffect(()=>{
    console.log("selectedParserSetting", selectedParserSetting)
  },[selectedParserSetting])

  useEffect(() => {
    if (props.userData && props.userData.selectedBusiness) {
      console.log("calling getlinkedsuppliers...")
      getLinkedSuppliers()
    }
  }, [props.userData.selectedBusiness]);

  useEffect(() => {
    console.log("ExpressMode", expressMode)
  }, [expressMode])

  const getLinkedSuppliers = () => {
    setUILoading(true)
    const sb = props.userData.selectedBusiness;
    getSOLinkedSuppliers(sb.business_id)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        setUILoading(false)
        if (data.master_company_exists === true) {
          setMasterCompanyExists(true)
        }
        const sortedLinkedSuppliers = sortBy(data.results, 'supplier_name')
        setLinkedSuppliers(sortedLinkedSuppliers);
        if (activeStep == 1) {
          setSupplierLinked(true)
        }
      })
      .catch((error: any) => {
        setUILoading(false)
        setLinkedSuppliers([]);
      });
  }

  useEffect(() => {
    if (selectedSupplier) {
      setSupplierError(NoError);
    }
  }, [selectedSupplier]);

  useEffect(() => {
    if (selectedFile) {
      setSelectedFileError(NoError);
    }
  }, [selectedSupplier]);

  useEffect(() => {
    if (stepOneSuccess) {
      setActiveStep(1);
    }
  }, [stepOneSuccess, selectedSupplier]);

  useEffect(() => {
    console.log("PROPS----------------", props)
  }, [props])


  // useEffect(() => {
  //   if (stepTwoSuccess && expressMode) {
  //     props.showToast({ message: 'PDF Info Found moving to step 3!', options: ToastSuccess })
  //     setActiveStep(2);
  //     handleNext()
  //   }

  // }, [stepTwoSuccess])

  // useEffect(() => {
  //   if (stepThreeSuccess && expressMode && mappedHeaders) { // include && headersError.hasError here to not immediately render screen 4 after solving mapping errors, instead user will need to click on next button manually 
  //     handleNext()
  //   }

  // }, [stepThreeSuccess, mappedHeaders])

  useEffect(() => {
    console.log("expressMode", expressMode);
  }, [expressMode])

  // useEffect(() => {
  //   console.log("stepFour", stepFourSuccess)
  //   // setActiveStep(3)
  //   if (stepFourSuccess && expressMode)
  //     handleNext()
  // }, [stepFourSuccess])

  useEffect(() => {
    if (activeStep === steps.length) {
      confirmReset();
    }
  }, [activeStep]);




  useEffect(() => {
    if (props.userData.selectedLocations && props.userData.selectedLocations.length > 0) {
      let selectedLocs = props.userData.selectedLocations;
      console.log("SELECTED-LOCSSS~~", selectedLocs)
      if (activeStep === 1) {
        if (selectedLocs.length === 1) {
          selectedOrgUnit.current = selectedLocs[0];
        } else {
          selectedOrgUnit.current = null
        }
      }

    }
    else {
      selectedOrgUnit.current = null
    }
  }, [props.userData, activeStep, selectedSupplier]);



  useEffect(() => {
    const { stockOrderProductList } = props;
    if (stockOrderProductList && stockOrderProductList.error) {
      let msg = 'Failed to Match Invoice Items!';
      props.showToast({ message: msg, options: ToastError });
    }
    if (stockOrderProductList && stockOrderProductList.data) {
      if (stockOrderProductList.data.length > 0) {
        console.log("stockOrderProductList", stockOrderProductList.data)
        const matchedItemSummary = getMatchedItemSummary(stockOrderProductList, stockOrderSummary);
        // console.log("Matched items summary",matchedItemSummary);
        setStockOrderProducts((prevObj:any)=>matchedItemSummary.items);
        setStockOrderSummary((prevObj:any)=>matchedItemSummary.summary);
      }
    }
  }, [props.stockOrderProductList]);
  
  useEffect(() => {
    if (selectedParserSetting) {
      mapHeadersFromConfig();
    }
  }, [selectedParserSetting]);

  useEffect(() => {
    if (props.createStockOrderData.success) {
      props.showToast({ message: 'Stock Order created successfully!', options: ToastSuccess });
      setUILoading(false)
      setBackButtonDisabled(true)
    }
    if (props.createStockOrderData.error) {
      let msg = 'Failed to create Stock Order!';
      const { errorData } = props.createStockOrderData;
      if (errorData) {
        // if ((errorData.status === 400 )){
        //   msg=' Description is required to create Stock Order'; 
        // }
        if ((errorData.status === 403 || errorData.status === 401)) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        } else if (errorData.status === 500) {
          msg = ERROR_500;
        }
      }
      props.showToast({ message: msg, options: ToastError });
      setCreateStockOrderButtonDisabled(false)
      setUILoading(false)
    }
  }, [props.createStockOrderData]);



  const handleReset = () => {
    setResetDialogOpen(true);

  }


  const handleResetDialogClose = () => {
    setResetDialogOpen(false);
  };

  const resetDialog = () => {
    return (
      <div>
        <Dialog
          open={resetDialogOpen}
          onClose={handleResetDialogClose}
        >
          <DialogTitle id="alert-dialog-title">{"Reset"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to reset?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResetDialogClose} color="primary" >
              No
            </Button>
            <Button onClick={confirmReset} style={{ color: GlobalStyles.red }} autoFocus >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }


  const handleAlertDialogClose = () => {
    setShowAlertRtlLocNotFound(false);
  };


  const renderAlertDialog = () => {
    return (
      <div>
        <Dialog
          open={showAlertRtlLocNotFound}
          onClose={handleAlertDialogClose}
        >
          <DialogTitle>{"Retailer Location not found"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography>{rtlLocErrorMsg}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAlertDialogClose} style={{ color: GlobalStyles.primaryColor }} autoFocus >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const setCurrentLocation = () => {
    if (props.userData && props.userData.selectedLocations && props.userData.selectedLocations.length > 0) {
      let selectedLocs = props.userData.selectedLocations;
      console.log("SELECTED-LOCSSS~~", selectedLocs)
      if (activeStep === 1) {
        if (selectedLocs.length === 1) {
          selectedOrgUnit.current = selectedLocs[0];
        } else {
          selectedOrgUnit.current = null
        }
      }

    }
    else {
      selectedOrgUnit.current = null
    }
  }



  const parseCSV = () => {
    setUILoading(true)
    if (selectedFile) {
      const CSVParserConfig = {
        complete: function (results: any, file: any) {
          // console.log('results', results)
          // console.log("Finished:", results.data);
          if (results.data.length > 0) {
            // console.log('papa parser', results.data);
            mapCSVDataToGrid(results.data);
            mapHeadersFromConfig();
            // setStepOneSuccess(true);
            setActiveStep(1);
            setUILoading(false)
          }

        },
        dynamicTyping: false,
        error: function (error: any, file: any) {
          console.log("Error occurred while parsing csv!");

        },
        skipEmptyLines: true
      };
      Papa.parse(selectedFile, CSVParserConfig);
    }
  };

  const mapHeadersFromConfig = () => {
    let config: any = null;
    console.log("selectedParserSetting", selectedParserSetting);
    if (selectedParserSetting) {

      
      if (selectedParserSetting.stock_order_header_config) {
        try {
          config = JSON.parse(selectedParserSetting.stock_order_header_config);
        }
        catch (e) {
          // error parsing header config
          config = null;
        }
      }
      if (config) {
        // console.log("config", config)
        if ("columnsToIgnore" in config) {
          setCheckColumnsInput((prevState:any)=>config.columnsToIgnore);
        }
        if ("rowsToIgnore" in config) {
          setCheckRowsInput((prevState:any)=>config.rowsToIgnore);
        }
        if ("columns" in config) {
          const map = new Map<number, string>()
          // console.log("Before mappedHeaders", mappedHeaders)
          // console.log("config.columns", config.columns)
          Object.keys(config.columns).forEach(k => {
            const header = definedHeaders.find(h => h.toLowerCase() === config.columns[k].toLowerCase());
            if (header) {
              map.set(Number(k), header);
            }
          });
          // console.log("Updatedmap",map)
          setMappedHeaders((prevState:any)=>map);
        }
        triggerRowColSelection((prevState:any)=>!shouldUpdateRowColSelection);
      }
    }
  };

  const validateCaseQuantities = () => {
    const caseQtyBlankError = {
      hasError: true,
      message: 'Supplier Case Quantity cannot be blank.'
    };
    const { stockOrderProductList } = props;
    console.log("stockOrderProductList: " + stockOrderProductList);
    if (stockOrderProductList &&
      stockOrderProductList.data &&
      stockOrderProductList.data.length > 0) {
      const list = stockOrderProductList.data.slice();
      if (isCaseQtyMapped) {
        for (let i = 0; i < list.length; i++) {
          if (!list[i].master_supplier_container_qty) {
            setCaseQtyDataError(caseQtyBlankError);
            return false;
          }
          if (Number(list[i].caseQuantity) !== Number(list[i].master_supplier_container_qty)) {
            setCaseQtyDataError(
              {
                hasError: true,
                message: 'Please resolve the difference between Case Quantity and Supplier Case Quantity Values.'
              });
            return false;
          }
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          if (!list[i].master_supplier_container_qty) {
            setCaseQtyDataError(caseQtyBlankError);
            return false;
          }
        }
      }
      return true;
    }
    return false;
  };

  const handleNext = () => {
    
    console.log("active step", activeStep);
    if (activeStep === 0) {
      // if (stepOneSuccess) {
      //   setActiveStep(1);
      //   return;
      // }
    }
    else if (activeStep === 1) {
      setUILoading(true);

      if (!isFileExtensionPDF) {
        setUILoading(false)
        setActiveStep(2);
        return;
      }


      const sb = props.userData.selectedBusiness.business_id;
      const selected_supplier = masterCompanyExists ? selectedSupplier.master_company.id : selectedSupplier.korona_supplier_business.id
      // let master_company_exists = true
      const parser_setting_id = selectedParserSetting ? selectedParserSetting.id : '';
      parsePdf(sb, awsBlockResponseId, selected_supplier, masterCompanyExists, parser_setting_id)
        .catch(handleError)
        .then(checkStatus)
        .then(parseJSON)
        .then(res => {
          
          if (res['parsed_data'].length === 0) {
            const msg = 'Oops! An Error Occured. Data could not be parsed from file.';
            props.showToast({ message: msg, options: ToastError });
          }

          setParsedPdfData(res['parsed_data']);
          mapCSVDataToGrid(res['parsed_data']);
          if (res['headers']) {
            if (res['headers']['description']) {
              setDescription(res['headers']['description'])
            }
          }
          mapHeadersFromConfig();
          setActiveStep(2)
          console.log("CAMEEEE API CALL")
          setUILoading(false);
        })
        .catch((error: any) => {
          setUILoading(false)

          if (error.status === 400) {
            props.showToast({ message: error.data.message, options: ToastError });
          }
          else if (error.status === 404) {
            props.showToast({ message: error.data.message, options: ToastError });
          }
          else {
            const msg = 'Oops! An Error Occured.';
            props.showToast({ message: msg, options: ToastError });
          }

        });

    }
    else if (activeStep === 2) {
        setUILoading(true);

      // console.log("inside STEP2")
      const headersMappedResult = validateHeaders(mappedHeaders);
      if (headersMappedResult.hasError) {
        setHeadersError(headersMappedResult);
        setUILoading(false)

        return;
      } else {
        setUILoading(false)

        props.showToast({ message: 'Mapped Headers Successfully, Moving to Step 4!', options: ToastSuccess });
        setHeadersError(NoErrorInHeaders);
      }

      if (selectedSupplier && !headersMappedResult.hasError) {
        setActiveStep(prevActiveStep => {
          // console.log("prev_active_step",prevActiveStep);
          if (prevActiveStep + 1 === 3) {
            getStockOrderProducts();
            setUILoading(false)

          }
          return prevActiveStep + 1;
        });
      }
    } else if (activeStep === 3) {
      setUILoading(true);
      const areCaseQuantitiesValid = validateCaseQuantities();
      // console.log("areCaseQuantitiesValid",areCaseQuantitiesValid);
      if (areCaseQuantitiesValid) {
        createSO();
      }
    } else {
      setUILoading(false);
      
      setActiveStep(activeStep + 1);
    }

  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };



  const confirmReset = () => {
    setDescription(`Invdt:-           Invnum:-`)
    setParsedPdfData(null as any)
    setSelectedFile(null)
    setSelectedFileError(NoError)
    setSupplierError(NoError)
    setHeadersError(NoErrorInHeaders)
    setLinkedSuppliers([])
    triggerRowColSelection(false)
    setCheckRowsInput('')
    setCheckColumnsInput('')
    setSelectedFile(null);
    setGridData([]);
    setSelectedSupplier(null);
    setMappedHeaders(new Map<number, string>());
    setStockOrderSummary(initialStockOrderSummary);
    setSpecificHeadersMap(initSpecifiHeaders);
    props.clearStockOrderProductList();
    setStockOrderProducts([]);
    setStepOneSuccess(false);
    setActiveStep(0);
    setPDFUploaded(false);
    setAwsBlockResponseId(0)
    setSupplierLinked(true)
    setUILoading(false);
    setIsFileExtensionPDF(true);
    setCurrentLocation()
    setBackButtonDisabled(false);
    setStepTwoSuccess(false);
    setStepThreeSuccess(false);
    setStepFourSuccess(false);
    setPDFInfoExists(false);
    setExpressMode(false);
    setCreateStockOrderButtonDisabled(false)
    setReloadMappingButtonDisabled(false)
    getLinkedSuppliers()
    selectedOrgUnit.current = null
    setSelectedSupplierManually(null)
    setParserModel(null)
    setSelectedParserSetting(null)
    setShouldSelectedParserSetting(false)
    setTextractModel("azure_textract" as any)
    setSupplierNameNotLinked('')
    setMasterCompanyExists(false)
    setShowAlertRtlLocNotFound(false)
    setDeleteLinkDialogOpen(false)
    setSelectedItemtoUnlink(false)
    setParserModel(null)
    setSOComment('')
    setAddtlExpData([{ type: '', amount: '' }])
    setOriginalDataSummary(null)

    setResetDialogOpen(false)

    //props.setSelectedLocations(props.userData.selectedLocations)
  };

  const getStockOrderProducts = () => {
    // console.log("getStockOrderProducts called");
    // setSpecificHeadersMap(initSpecifiHeaders);
    setCaseQtyDataError(NoError);
    props.clearStockOrderProductList();
    console.log("matchedHeaders",mappedHeaders)
    const matchedHeaders = getMatchedHeaders(gridData, mappedHeaders);
    setSpecificHeadersMap(matchedHeaders);
    const sb = props.userData.selectedBusiness;
    const businessId = sb.business_id;
    const requestData = getInvoiceItemsToMatch(gridData, mappedHeaders);
    // console.log("matchedHeaders",matchedHeaders)
    // Above code will be used to merge duplicate records
    let config: any = null;
    console.log("selectedSupplier", selectedSupplier);
    if (selectedSupplier) {
      // const { parser_settings } = selectedSupplier.master_company;
      const { parser_settings } = masterCompanyExists ? selectedSupplier.master_company : selectedSupplier.korona_supplier_business;
      if (parser_settings && parser_settings.length > 0) {
        
          if (parser_settings.length > 1) {
            setShouldSelectedParserSetting(true);
          } else if (parser_settings.length == 1) {
            const setting =  parser_settings[0];
            setSelectedParserSetting(setting);
            try {
              config = JSON.parse(setting.stock_order_header_config);
              //console.log(config);
            }
            catch (e) {
              // error parsing header config
              config = null;
            }
          }
        
        
        
      }
    }

    const RegexToMatch = (data) => {
      console.log("config.product_regex",config.product_regex)
      let parser_regex = config.product_regex
      let regex_to_match = new RegExp(parser_regex);
      data.forEach(element => {
        element.supplierProductName = element.supplierProductName.match(regex_to_match)[0];
      })

      return data;
    }

    // To merge duplicate records
    const MergeDuplicateRecords = () => {
      let merged_duplicate_product_lists = []
      for (let i = 0; i < requestData.length; i++) {
        let existing_product = merged_duplicate_product_lists.find(element => element.reOrderCode === requestData[i].reOrderCode)
        if (existing_product) {
          // console.log("inside existing_product")
          if (definedHeaders.indexOf(EXPECTED_HEADERS.CASES_DELIVERED) > -1) {
            if (requestData[i].casesDelivered && requestData[i].casesDelivered > 0) {
              existing_product.casesDelivered += Number(requestData[i].casesDelivered)
            }
          }
          else if (definedHeaders.indexOf(EXPECTED_HEADERS.UNITS_DELIVERED) > -1) {
            if (requestData[i].unitsDelivered > 0) {
              existing_product.unitsDelivered += Number(requestData[i].unitsDelivered)
            }
          }
          if (definedHeaders.indexOf(EXPECTED_HEADERS.COST_PER_CASE) > -1) {
            if (requestData[i].casesDelivered > 0) {
              existing_product.costPerCase = Number(existing_product.lineTotal) / Number(existing_product.casesDelivered)
              existing_product.costPerCase = existing_product.costPerCase.toFixed(2)
            }
          }
          else if (definedHeaders.indexOf(EXPECTED_HEADERS.COST_PER_UNIT) > -1) {
            if (requestData[i].unitsDelivered > 0) {
              existing_product.costPerUnit = Number(existing_product.lineTotal) / Number(existing_product.unitsDelivered)
              existing_product.costPerUnit = existing_product.costPerUnit.toFixed(2)
            }
          }
          if (existing_product.supplierProductName.length < requestData[i].supplierProductName.length) {// take the name with shorter length
            continue
          }
          else {
            existing_product.supplierProductName = requestData[i].supplierProductName
          }
        }
        else {
          merged_duplicate_product_lists.push(requestData[i])
        }
      }
      return merged_duplicate_product_lists
    }


    const checkMultipleProductName = (data: any) => {
      const validatedRecords = data.map(i => i);
      let productName;
      let firstLetter;
      let secondWordStart;
      let replacedInput;
      let newInput;
      for (let i = 0; i < validatedRecords.length; i++) {
        const exists = validatedRecords[i].supplierProductName ? true : false;
        if (exists) {
          let { supplierProductName } = validatedRecords[i];
          // const {input } = supplierProductName;
          productName = supplierProductName
          firstLetter = productName.charAt(0)
          secondWordStart = productName.split(firstLetter, 2).join(firstLetter).length;
          // console.log("secondWordStart",secondWordStart)
          newInput = productName.slice(secondWordStart);
          // console.log("NEW-INPUT",newInput)
          validatedRecords[i]["supplierProductName"] = newInput
        }
      }
      RegexToMatch(validatedRecords)
      return validatedRecords;
    }

    if (businessId && selectedSupplier && requestData.length > 0) {

      let data = []
      let newData = []
      let array_of_header_values = []
      let values_of_headers_list = Array.from(mappedHeaders.values());
      let payload = {}
      let annapurnaBusiness = "annapurna"
      if (values_of_headers_list.includes(EXPECTED_HEADERS.RE_ORDER_CODE)) {
        //if (mappedHeaders).indexOf(EXPECTED_HEADERS.RE_ORDER_CODE) >-1) {
        data = MergeDuplicateRecords()
        // console.log(newData)
        //RegexToMatch(data)
        RegexToMatch(data)
        //console.log("REORDER CODE FOUND")  
      }
      else if (selectedSupplier.master_company.name.toLowerCase().includes(annapurnaBusiness)) {
        data = checkMultipleProductName(requestData)
      }
      else {
        //console.log("REORDER CODE NOT FOUND")
        data = requestData
        // console.log("DATA",data)
        // console.log("newData",newData)
        RegexToMatch(data)
        //console.log("data",data)
      }
      const parser_setting_id = selectedParserSetting ? selectedParserSetting.id : '';
      payload = {
        stockOrderItems: data,
        business_id: businessId,
        master_company_id: selectedSupplier.master_company.id,
        korona_supplier_business_id: null,
        user_auth_token: props && props.userData && props.userData.userData.userData.auth_token,
        document_name: description,
        parser_setting_id:parser_setting_id
      };
      const originalDataMatchedItemSummary = getMatchedItemSummary({data: data}, stockOrderSummary);
      setOriginalDataSummary((prevObj:any)=> originalDataMatchedItemSummary )

      props.getStockOrderProductList(payload);
    } else {
      props.clearStockOrderProductList();
    }
  };


  const createSO = () => {
    if (selectedOrgUnit.current != null) {
      let currentLoc = selectedOrgUnit.current
      const requestData = getStockOrderRequestData(selectedSupplier, currentLoc, description, soComment, stockOrderProducts);
      setCreateStockOrderButtonDisabled(true)
      setReloadMappingButtonDisabled(true)
      setUILoading(true)
      // console.log('selectedOrgUnit', selectedOrgUnit);
      // console.log(stockOrderProducts);
      // console.log(requestData);
      if (!requestData.hasError) {
        let payload = cloneDeep(requestData.payload)
        console.log("propsssssss~~~~~~~~", props)
        console.log("payload-before", payload)
        payload['user_details'] = props.userData && props.userData.userData && props.userData.userData.userData.auth_token
        payload['business'] = props.userData && props.userData.selectedBusiness
        payload['description'] = description
        payload['master_company_id'] = selectedSupplier.master_company.id

        console.log("payload-after", payload)

        addStockOrder(payload)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(jsonData => {
            let msg = 'Successfully created'
            props.showToast({ message: msg, options: ToastSuccess });
            props.createStockOrderSuccess(jsonData)
            setUILoading(false)

          })
          .catch((error: any) => {
            
            console.log(error);
            setCreateStockOrderButtonDisabled(false)
            setReloadMappingButtonDisabled(false)
            setUILoading(false)
            if (error.status === 404) {
              props.showToast({ message: error.data.message, options: ToastError });
            }
            else {
              const msg = 'Oops! An Error Occured.';
              // console.log("in error",error)
              props.showToast({ message: msg, options: ToastError });
            }
          });

      }
    }

  };




  const handleLinkedSupplierChange = (event: any) => {
    let val = event.target.value
    let linkedSupplier = linkedSuppliers.find(s => s.id === val);
    setSelectedSupplier(linkedSupplier);
    setSelectedSupplierManually(linkedSupplier);
    // val === 0 ?  setSelectedSupplierNone(true) : setSelectedSupplierNone(false)
  };

  const onFileSelectChangeHandler = (event: any) => {
    if (event.target) {
      if (event.target.files && event.target.files[0]) {
        setSelectedFile(event.target.files[0]);
      }
    }
  };

  const mapCSVDataToGrid = (data: any[][]) => {
    let result: GridElement[][] = [];
    for (let i = -1; i < data.length; i++) {
      let row = null;
      if (i === -1) {
        row = data[0];
      } else {
        row = data[i];
      }
      let newRow: GridElement[] = [];
      for (let j = -1; j < row.length; j++) {
        const gridElement = { value: '', isSelected: false, isIgnored: false, type: 'data' } as GridElement;
        if (i === -1 && j === -1) { // row=0, col=0
          gridElement.type = 'data';
          // gridElement.value = 'Headers'
        } else if (i === -1) { // row=0
          gridElement.type = 'header';
        }
        else if (j === -1) { // col=0
          gridElement.type = 'checkbox';
        } else {
          gridElement.type = 'data';
          if (!row[j]) {
            gridElement.value = '';
          } else {
            gridElement.value = row[j];
          }
        }
        newRow.push(gridElement);
      }
      result.push(newRow);
    }
    setGridData(result);
  };

  const uploadPDF = () => {
    if (selectedFile) {
      if (!isPDFUploaded) {
        //const sb = props.userData.selectedBusiness;
        const data = new FormData();
        data.append('file', selectedFile);
        data.append('master_company_exists', String(masterCompanyExists));
        data.append('textract_model', String(textractModel));
        //data.append('business_id', String(sb.business_id));
        setUILoading(true);
        uploadInvoicePDf(data)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(res => {
            setActiveStep(1)
            props.showToast({ message: 'PDF Uploaded Successfully!', options: ToastSuccess });
            setPDFUploaded(true);
            if (res['pdf_info_exists']) {
              setPDFInfoExists(true)
            }
            if (res['pdf_info_exists'] && !res['linked_supplier']) {
              setSupplierLinked(false);
              setSupplierNameNotLinked(res['supplier_not_linked'])
            }

            if (!res['pdf_info_exists']) {
              const msg = 'Supplier,Business and location could not be retrived for the data parsed from this file, Please choose manually'
              props.showToast({ message: msg, options: ToastError });
            }
            props.setSelectedBusiness(res['retailer_business'])
            if (res['location'] != null) {
              const { selectedLocations } = props.userData
              selectedLocations.forEach(element => {
                if (element.id != res['location']) {
                  const index = selectedLocations.indexOf(element)
                  selectedLocations[index].selected = false;
                  selectedLocations.splice(index, 1);
                }
              })

              props.setSelectedLocations(selectedLocations);
              selectedOrgUnit.current = selectedLocations[0]

            }
            // console.log(selectedLocations[0])
            setAwsBlockResponseId(res['stored_aws_response_id'])
            setSelectedSupplier(res['linked_supplier'])
            setSelectedSupplierManually(res['linked_supplier'])
            if (res['linked_supplier']) {
              const { parser_settings } = masterCompanyExists ? res['linked_supplier'].master_company : res['linked_supplier'].korona_supplier_business;
              console.log("parser_settings----SO", parser_settings)
              if (parser_settings) {
                if (parser_settings.length > 1) {
                  setShouldSelectedParserSetting(true);
                } else if (parser_settings.length == 0) {
                  setSelectedParserSetting(parser_settings[0]);
                }
              }
              
            }
            
            setUILoading(false);


            // ----------------------------------------------------------------------------------------------------
            // props.showToast({ message: 'PDF Uploaded Successfully!', options: ToastSuccess });
            // setPDFUploaded(true);
            // setAwsBlockResponseId(res['stored_aws_response_id'])
            // setSelectedSupplier(res['linked_supplier'])

            // if (res['pdf_info_exists']) {
            //   setPDFInfoExists(true)
            // }
            // if (res['pdf_info_exists'] && !res['linked_supplier']) {
            //   setSupplierLinked(false);
            //   setSupplierNameNotLinked(res['supplier_not_linked'])
            // }

            // if (!res['pdf_info_exists']) {
            //   const msg = 'Supplier,Business and location could not be retrived for the data parsed from this file, Please choose manually'
            //   props.showToast({ message: msg, options: ToastError });
            // }
            // props.setSelectedBusiness(res['retailer_business'])
            // if (res['location'] != null) {
            //   //STORE IS ACCESSED DIRECTLY, HACKY APPROACH.
            //   const { selectedLocations } = store.getState().userData
            //   // console.log("STORE ACCESSED DIRECTLY",store.getState())

            //   //const  {selectedLocations}  =props.userData
            //   selectedLocations.forEach(element => {
            //     if (element.id != res['location']) {

            //       const index = selectedLocations.indexOf(element)
            //       selectedLocations[index].selected = false;
            //       selectedLocations.splice(index, 1);
            //     }
            //   })

            //   // console.log("INSIDE_API_RESPONSE~~~~~~~~~",selectedLocations);
            //   props.setSelectedLocations(selectedLocations);
            //   selectedOrgUnit.current = selectedLocations[0]

            // }
            // // console.log(selectedLocations[0])
            // setActiveStep(1)

            // // setStepOneSuccess(true);
            // setUILoading(false);
          })
          .catch((error: any) => {
            setUILoading(false)
            setActiveStep(1)
            setPDFUploaded(true);


            if (error.status === 404) {
              // confirmReset()
              props.showToast({ message: error.data.message, options: ToastError });
            }
            else {
              const msg = 'Oops! An Error Occured.';
              // console.log("in error",error)
              props.showToast({ message: msg, options: ToastError });
              // confirmReset()
            }

          });
      }
    }
  };


  const exportToCSV = () => {
    if (parsedPdfData && selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop();
      if (fileExtension === 'pdf') {
        let csvContent = "data:text/csv;charset=utf-8,"
          + parsedPdfData.map((e: any) => e.join("\t")).join("\n");
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        const filename = selectedFile.name ? selectedFile.name : `invoice_${moment().format('YYYY_MM_DD')}`;
        link.setAttribute("download", `${filename}.tsv`);
        document.body.appendChild(link); // Required for FF
        link.click();
      }
    }
  };

  const FunctionIsFileExtensionPdf = () => {
    setIsFileExtensionPDF(false)
  }

  const renderFileName = () => {
    if (selectedFile && selectedFile.name) {
      const ext = selectedFile.name.split('.').pop();
      const filename = `File: ${selectedFile.name}`;
      if (ext === "pdf") {
        return (
          <>
            <span className={classes.secondaryPageTitle}>
              &nbsp;&nbsp;&ndash;&nbsp;&nbsp;{filename}
            </span>
            {
              parsedPdfData &&
              <Tooltip title="Export TSV" onClick={exportToCSV}
                style={{ alignSelf: 'center', marginLeft: 8 }}>
                <IconButton aria-label="export-tsv">
                  <SaveAltIcon />
                </IconButton>
              </Tooltip>
            }
          </>);
      }
      return filename;
    }
    return null;
  };

  const getStepContent = (stepIndex: number) => {
    // console.log("getStepContent called -- stepIndex --", stepIndex);
    switch (stepIndex) {
      case 0:
        return (
          <FileImport
            history={props.history}
            uploadPDF={uploadPDF}
            onFileSelectChangeHandler={onFileSelectChangeHandler}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            textractModel={textractModel}
            setTextractModel={setTextractModel}
            selectedFileError={selectedFileError}
            selectedSupplier={selectedSupplier}
            setSelectedSupplier={setSelectedSupplier}
            supplierError={supplierError}
            uiLoading={uiLoading}
            setUILoading={setUILoading}
            handleLinkedSupplierChange={handleLinkedSupplierChange}
            linkedSuppliers={linkedSuppliers}
            mapCSVDataToGrid={mapCSVDataToGrid}
            parseCSV={parseCSV}
            stepOneSuccess={stepOneSuccess}
            setIsFileExtensionPDF={setIsFileExtensionPDF}
          // setExpressMode={setExpressMode}
          // expressMode={expressMode}


          />
        );

      case 1: return (
        <ConfirmSupplierAndLocation
          history={props.history}
          supplierError={supplierError}
          uiLoading={uiLoading}
          linkedSuppliers={linkedSuppliers}
          handleLinkedSupplierChange={handleLinkedSupplierChange}
          selectedSupplier={selectedSupplier}
          selectedOrgUnit={selectedOrgUnit.current}
          supplierLinked={supplierLinked}
          supplierNameNotLinked={supplierNameNotLinked}
          shouldSelectParserSetting={shouldSelectParserSetting}
          setShouldSelectedParserSetting={setShouldSelectedParserSetting}
          // expressMode={expressMode}
          pdfInfoExists={pdfInfoExists}
          setStepTwoSuccess={setStepTwoSuccess}
          setExpressMode={setExpressMode}
          setSelectedSupplier={setSelectedSupplier}
          setSelectedSupplierManually={setSelectedSupplierManually}
          selectedSupplierManually={selectedSupplierManually}
          documentName ={description}
          setDocumentName={setDescription}
          textractModel={textractModel}
          setTextractModel={setTextractModel}
          selectedParserSetting={selectedParserSetting}
          setSelectedParserSetting={setSelectedParserSetting}
          setParserModel={setParserModel}
          parserModel={parserModel}

        />
      );
      case 2:
        return (
          <VerifyImportData
            parsedPdfData={parsedPdfData}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            selectedFileError={selectedFileError}
            mapCSVDataToGrid={mapCSVDataToGrid}
            mapHeadersFromConfig={mapHeadersFromConfig}
            gridData={gridData}
            setGridData={setGridData}
            parseCSV={parseCSV}
            selectedSupplier={selectedSupplier}
            setSelectedSupplier={setSelectedSupplier}
            supplierError={supplierError}
            mappedHeaders={mappedHeaders}
            setMappedHeaders={setMappedHeaders}
            headersError={headersError}
            uiLoading={uiLoading}
            setUILoading={setUILoading}
            windowWidth={width}
            windowHeight={height}
            checkColumnsInput={checkColumnsInput}
            setCheckColumnsInput={setCheckColumnsInput}
            checkRowsInput={checkRowsInput}
            setCheckRowsInput={setCheckRowsInput}
            definedHeaders={definedHeaders}
            shouldUpdateRowColSelection={shouldUpdateRowColSelection}
            setStepThreeSuccess={setStepThreeSuccess}
            selectedParserSetting={selectedParserSetting}
            triggerRowColSelection={triggerRowColSelection}
            
          // expressMode={expressMode}

          />
        );

      case 3:
        return (
          <MatchInvoiceProduct
            originalDataSummary={originalDataSummary}
            selectedSupplier={selectedSupplier}
            stockOrderSummary={stockOrderSummary}
            getStockOrderProducts={getStockOrderProducts}
            specificHeaders={specificHeaders}
            caseQtyDataError={caseQtyDataError}
            isCaseQtyMapped={isCaseQtyMapped}
            uiLoading={uiLoading}
            setUILoading={setUILoading}
            windowWidth={width}
            windowHeight={height}
            // expressMode={expressMode}
            setStepFourSuccess={setStepFourSuccess}
            reloadMappingButtonDisabled={reloadMappingButtonDisabled}
            description={description}
            deleteLinkDialogOpen={deleteLinkDialogOpen}
            setDeleteLinkDialogOpen={(d:boolean)=>setDeleteLinkDialogOpen(d)}
            setSelectedItemtoUnlink={(item:any)=>setSelectedItemtoUnlink(item)}
            selectedItemtoUnlink={selectedItemtoUnlink}
            selectedParserSetting={selectedParserSetting}
            setSOComment={(cmnt:any)=>setSOComment(cmnt)}
            soComment={soComment}
            addtlExpData ={addtlExpData}
            setAddtlExpData={(adData:any)=>setAddtlExpData(adData)}
          />
        );
      default:
        return 'Uknown stepIndex';
    }
  };


  const nextButtonDisabled = () => {
    let val = false
    if (uiLoading) {
      val = true
    }
    else if (!selectedFile) {
      val = true
    }
    else if (createStockOrderButtonDisabled) {
      val = true
    }
    else if (activeStep === 1) {
      if (!selectedSupplier) {
        val = true
      }
    }

    return val
  }

  return (
    <div style={{ maxWidth: '100%', marginTop: -16 }}>
      <PageToolbar
        title='Import Stock Order'
        history={props.history}
        secondaryTitle={renderFileName()}
        actions={
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {
              activeStep === 0 &&
              <div style={{ alignSelf: 'center', marginTop: 8 }}>
                <Button variant="contained" disabled={selectedFile ? false : true} onClick={handleReset}>Reset</Button>
              </div>
            }
            {
              activeStep > 0 &&
              <>
                <div style={{ alignSelf: 'center', marginRight: 8, marginTop: 8 }}>
                  <Button variant="contained" onClick={handleReset}>Reset</Button>
                </div>
                <div style={{ display: 'flex', minWidth: 200, maxWidth: 290, width: '100%' }}>
                  {
                    <TextField
                      id="supplier"
                      label="Supplier"
                      name="supplier"
                      disabled={true}
                      value={selectedSupplier ? selectedSupplier.korona_supplier.name : ''}
                    />
                  }
                </div>
              </>
            }
          </div>
        } />
      <Paper elevation={activeStep === 2 ? 0 : 4}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }} >
          <div className={classes.content} >
            {
              activeStep !== 0 &&
              <Button
                disabled={backButtonDisabled}
                variant="contained"
                onClick={handleBack}
              >
                Back
              </Button>
            }
            <Stepper
              style={{ padding: 0, paddingTop: 24, flex: 1 }}
              activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {
              activeStep === 3 &&
              <TextField
                id="description-textarea"
                label="Description"
                placeholder="Description"
                style={{ marginRight: 16 }}
                multiline
                margin="normal"
                value={description}
                disabled={true}
                // onChange={(e) => setDescription(e.target.value)}
              />
            }
            {
              <Button variant="contained" color="primary" onClick={handleNext} disabled={nextButtonDisabled()}>
                {activeStep === steps.length - 2 ? 'Create Stock Order' : 'Next'}
              </Button>
            }
          </div>
          <Divider className={classes.divider} />
          {
            uiLoading ?
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
              :
              <div style={{ marginTop: 0 }}>
                {getStepContent(activeStep && activeStep)}
              </div>
          }

        </div>
      </Paper>
      {resetDialog()}
      {renderAlertDialog()}
    </div>
  );

};

const mapStateToProps = (state: any) => {
  return {
    stockOrderProductList: state.stockOrderModuleData.stockOrderProductList,
    createStockOrderData: state.stockOrderModuleData.createStockOrderData,
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getStockOrderProductList: (payload: any) => dispatch(getMatchedInvoiceItems(payload)),
    clearStockOrderProductList: () => dispatch(clearStockOrderProducts()),
    setSelectedBusiness: (business: any | undefined) =>
      dispatch(setSelectedBusiness(business)),
    setSelectedLocations: (locations: any) => dispatch(setSelectedLocations(locations)),
    createStockOrder: (data: any) => dispatch(createStockOrderAPI(data)),
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    createStockOrderSuccess :(data:any)=>dispatch(createStockOrderSuccess(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockOrderImportScreen);