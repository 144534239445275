import {
    GET_PRODUCT_BY_CODE, GET_PRODUCT_BY_CODE_SUCCESS, GET_PRODUCT_BY_CODE_FAILURE, CLEAR_PRODUCT_FROM_STORE,
    GET_PRODUCT_BY_CODE_AND_SUPPLIER, GET_PRODUCT_BY_CODE_AND_SUPPLIER_SUCCESS, GET_PRODUCT_BY_CODE_AND_SUPPLIER_FAILURE,

} from '../actions/actionTypes';
import { API_CONFIG } from '../../../shared/api/config';
import composeRequest from '../../../shared/api/core';
import { checkStatus, handleError, parseJSON } from '../../../shared/api/core';

import { FieldValues, GenerateZPLParams, ZPLUtil } from '../../../labeler/utils/ZPLConverter';
import { getZPLLabelPreview } from 'components/src/modules/labeler/data/actions/labelerActions';
import { LabelerTemplate } from '../../../labeler/models';
import { Product, ProductCode } from '../../models';
import moment from 'moment';
import { API_URLS } from '../../api/ApiUrls';
import { getLiterTax, getLiterTaxFormatted, getProductNameForLabel, getProductPriceFormatted, getProductPriceByPriceGroup, getRawProductPrice, getSalesTax, getSalesTaxFormatted, getProductNameForLabelFormatted, getGrossPriceFormatted } from '../helpers';

export const getProductByCodeFromAPI = (businessId: number | string,
    code: string) => {
    return (dispatch: any) => {
        dispatch(getProductByCode());
        let productCode = code.trim();
        const URL = API_URLS.productByCodeUrl + '?business_id=' + businessId +
            '&pcode=' + productCode;
        return composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: any) => {
                dispatch(getProductByCodeSuccess(data));
            })
            .catch((err: any) => {
                // console.log(err); 
                dispatch(getProductByCodeFailure(err));
            });
    };
};




// Combined Reducer
export const getProductAndTemplatePreview = (businessId: number | string,
    code: string, selectedTemplate: LabelerTemplate) => {
    return (dispatch: any, getState: any) => {
        return dispatch(getProductByCodeFromAPI(businessId, code)).then(() => {
            // console.log('getProductAndTemplatePreview', getState().product)
            const { error, errorData, product } = getState().product;
            const labelerData = getState().labelerData;
            let literTaxUUID = null;
            if (labelerData && labelerData.hasOwnProperty("literTaxUUID") && labelerData.literTaxUUID.hasOwnProperty("data")) {
                literTaxUUID = labelerData.literTaxUUID.data;
                console.log("litertaxuuid", literTaxUUID);
            }
            if (!error && !errorData) {
                if (selectedTemplate && !product.error) {
                    const selTemp = selectedTemplate;
                    let params: GenerateZPLParams = {
                        template: selTemp,
                        product: product,
                        scanCode: code,
                        literTaxUUID: literTaxUUID,
                        isAutoAdjustEnabled: false,
                        currentPrintWidth: '',
                        isMultiPrintEnabled: false,
                        printQuantity: 1
                    }
                    const zpl = ZPLUtil.generateZPL(params)
                    return dispatch(getZPLLabelPreview(zpl));

                } else {
                    return;
                }
            }

        });
    };
};






const getProductByCode = () => {
    return {
        type: GET_PRODUCT_BY_CODE
    };
};

const getProductByCodeSuccess = (data: any) => {
    return {
        type: GET_PRODUCT_BY_CODE_SUCCESS,
        data
    };
};

const getProductByCodeFailure = (error: any) => {
    return {
        type: GET_PRODUCT_BY_CODE_FAILURE,
        error
    };
};

const getProductByCodeAndSupplier = () => {
    return {
        type: GET_PRODUCT_BY_CODE_AND_SUPPLIER
    };
};

const getProductByCodeAndSupplierSuccess = (data: any) => {
    return {
        type: GET_PRODUCT_BY_CODE_AND_SUPPLIER_SUCCESS,
        data
    };
};

const getProductByCodeAndSupplierFailure = (error: any) => {
    return {
        type: GET_PRODUCT_BY_CODE_AND_SUPPLIER_FAILURE,
        error
    };
};

export const removeProductFromStore = () => {
    return {
        type: CLEAR_PRODUCT_FROM_STORE
    };
};

export const selectProductForLabelerModule = (data: Product) => {
    return {
        type: GET_PRODUCT_BY_CODE_SUCCESS,
        data
    };
};

