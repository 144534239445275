import React, { useEffect, useState, useRef } from 'react';
import MaterialTable from 'material-table';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
import { formatDate, formatDate2 } from '../../../shared/lib/Localization';
import { getCustomerOrdersForStatus, getCustomerOrdersSummary } from '../../api';
import { connect } from 'react-redux';
import { Toast, ToastError, enqueueSnackbar } from '../../../shared/components/Notifier';
import { handleError } from '../../../shared/api/core';


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center',
        flex: 1
    },
    listContainer: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center',
    }
    
  }),
);


const OrderSummaryScreen = (props: any) => {
    const classes = useStyles({});
    const [selectedBusinessId, setSelectedBusinessId] = useState(props?.userData?.selectedBusiness?.business_id);
    
    const [newOrdersCount, setNewOrdersCount] = useState(null);
    const [inProcessOrdersCount, setInProcessOrdersCount] = useState(null);
    const [donePickingOrdersCount, setDonePickingOrdersCount] = useState(null);
    const [readyForPickupOrdersCount, setReadyForPickupOrdersCount] = useState(null);
    const [completedTodayOrdersCount, setCompletedTodayOrdersCount] = useState(null);
    
    const [newOrders, setNewOrders] = useState([]);
    const [inProcessOrders, setInProcessOrders] = useState([]);
    const [donePickingOrders, setDonePickingOrders] = useState([]);
    const [readyForPickupOrders, setReadyForPickupOrders] = useState([]);
    const [completedTodayOrders, setCompletedTodayOrders] = useState([]);
    


    useEffect(() => {
        console.log("selectedBusinessId", selectedBusinessId)
        getCustomerOrdersSummary(selectedBusinessId)
            .catch(handleError) // handle network issues
              .then(response => {
                const res = response && response.length == 1 ? response[0] : null
                console.log(response[0])
                console.log(res)
                if (res) {
                    setNewOrdersCount(res['NEW'])
                    setInProcessOrdersCount(res['IN PROCESS'])
                    setDonePickingOrdersCount(res['DONE PICKING'])
                    setReadyForPickupOrdersCount(res['READY FOR PICKUP'])
                    setCompletedTodayOrdersCount(res['COMPLETED TODAY'])
                }
              })
              .catch((error: any) => {
                // props.setPageLoader(false);
                props.showToast({
                  message: 'Failed to fetch order summary.',
                  options: ToastError
                });
              });
      }, [])
    useEffect(() => {
        getCustomerOrdersForStatus(selectedBusinessId, 'new')
            .catch(handleError) // handle network issues
              .then(res => {
                if (res) {
                    setNewOrders(res)
                }
              })
              .catch((error: any) => {
                // props.setPageLoader(false);
                props.showToast({
                  message: 'Failed to fetch new orders.',
                  options: ToastError
                });
              });
        getCustomerOrdersForStatus(selectedBusinessId, 'in_process')
            .catch(handleError) // handle network issues
              .then(res => {
                if (res) {
                    setInProcessOrders(res)
                }
              })
              .catch((error: any) => {
                // props.setPageLoader(false);
                props.showToast({
                  message: 'Failed to fetch in_process orders.',
                  options: ToastError
                });
              });
        getCustomerOrdersForStatus(selectedBusinessId, 'done_picking')
            .catch(handleError) // handle network issues
              .then(res => {
                if (res) {
                    setDonePickingOrders(res)
                }
              })
              .catch((error: any) => {
                // props.setPageLoader(false);
                props.showToast({
                  message: 'Failed to fetch done_picking orders.',
                  options: ToastError
                });
              });
        getCustomerOrdersForStatus(selectedBusinessId, 'ready_for_pickup')
            .catch(handleError) // handle network issues
              .then(res => {
                if (res) {
                    setReadyForPickupOrders(res)
                }
              })
              .catch((error: any) => {
                // props.setPageLoader(false);
                props.showToast({
                  message: 'Failed to fetch ready_for_pickup orders.',
                  options: ToastError
                });
              });
        getCustomerOrdersForStatus(selectedBusinessId, 'completed_today')
              .catch(handleError) // handle network issues
                .then(res => {
                  if (res) {
                      setCompletedTodayOrders(res)
                  }
                })
                .catch((error: any) => {
                  // props.setPageLoader(false);
                  props.showToast({
                    message: 'Failed to fetch completed_today orders.',
                    options: ToastError
                  });
                });
      }, [])
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} md>
                    <div className={classes.headerContainer}>
                        <h2>New Orders</h2>
                    </div>
                    <div className={classes.headerContainer}>
                        <h2>{newOrdersCount}</h2>
                    </div>
                    <div className={classes.listContainer}>
                        <List component="nav" aria-label="orders summary">
                            {
                                newOrders && newOrders.map(nw => {
                                    return (<ListItem>
                                        {/* <ListItemText primary={nw.order_number} /> */}
                                        <h5>{nw.order_number}-{nw.user ? nw.user.first_name : ''} {nw.user ? nw.user.last_name : ''}-{formatDate2(nw.created_time	)}</h5>
                                    </ListItem>)
                                })
                            }
                        </List>
                    </div>
                </Grid>
                <Grid item xs={12} md>
                    <div className={classes.headerContainer}>
                        <h2>In Process</h2>
                    </div>
                    <div className={classes.headerContainer}>
                        <h2>{inProcessOrdersCount}</h2>
                    </div>
                    <div className={classes.listContainer}>
                        <List component="nav" aria-label="orders summary">
                            {
                                inProcessOrders && inProcessOrders.map(nw => {
                                    return (<ListItem>
                                        <h5>{nw.order_number}-{nw.user ? nw.user.first_name : ''} {nw.user ? nw.user.last_name : ''}-{formatDate2(nw.created_time	)}</h5>
                                    </ListItem>)
                                })
                            }
                        </List>
                    </div>
                </Grid>
                <Grid item xs={12} md>
                    <div className={classes.headerContainer}>
                        <h2>Done Picking</h2>
                    </div>
                    <div className={classes.headerContainer}>
                        <h2>{donePickingOrdersCount}</h2>
                    </div>
                    <div className={classes.listContainer}>
                        <List component="nav" aria-label="orders summary">
                            {
                                donePickingOrders && donePickingOrders.map(nw => {
                                    return (<ListItem>
                                        <h5>{nw.order_number}-{nw.user ? nw.user.first_name : ''} {nw.user ? nw.user.last_name : ''}-{formatDate2(nw.created_time	)}</h5>
                                    </ListItem>)
                                })
                            }
                        </List>
                    </div>
                </Grid>
                <Grid item xs={12} md>
                    <div className={classes.headerContainer}>
                        <h2>Ready For Pickup</h2>
                    </div>
                    <div className={classes.headerContainer}>
                        <h2>{readyForPickupOrdersCount}</h2>
                    </div>
                    <div className={classes.listContainer}>
                        <List component="nav" aria-label="orders summary">
                            {
                                readyForPickupOrders && readyForPickupOrders.map(nw => {
                                    return (<ListItem>
                                        <h5>{nw.order_number}-{nw.user ? nw.user.first_name : ''} {nw.user ? nw.user.last_name : ''}-{formatDate2(nw.created_time	)}</h5>
                                    </ListItem>)
                                })
                            }
                        </List>
                    </div>
                </Grid>
                <Grid item xs={12} md>
                    <div className={classes.headerContainer}>
                        <h2>Completed Today</h2>
                    </div>
                    <div className={classes.headerContainer}>
                        <h2>{completedTodayOrdersCount}</h2>
                    </div>
                    <div className={classes.listContainer}>
                        <List component="nav" aria-label="orders summary">
                            {
                                completedTodayOrders && completedTodayOrders.map(nw => {
                                    return (<ListItem>
                                        <h5>{nw.order_number}-{nw.user ? nw.user.first_name : ''} {nw.user ? nw.user.last_name : ''}-{formatDate2(nw.booking_time	)}</h5>
                                    </ListItem>)
                                })
                            }
                        </List>
                    </div>
                </Grid>
            </Grid>


        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            
          </div>
        </div>
      );
}



const mapStateToProps = (state: any) => {
    return {
      userData: state.userLocalData,
    };
  };
  
const mapDispatchToProps = (dispatch: any) => {
    return {
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(OrderSummaryScreen);